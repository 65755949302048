<template>
  <div style="background: #F7F9FC;color: #000;height: 100%;">
    <van-nav-bar :title="orderData.carNo+' 结算清单'" left-arrow :fixed="true" :z-index="999" @click-left="back" />
    <div class="hestou" style>
      <div class="h-100">
        <div class="h-100 o-y-auto" style="background: #fafafa;padding-bottom:48px;">
          <van-row class="hedrow">
            <van-col span="11">车牌：{{orderData.carNo}}</van-col>
            <van-col class="ris" span="13">
              <div class="dis">单&#12288&#12288号：</div>
              <div class="dis shijian">{{orderData.billNumber}}</div>
            </van-col>
          </van-row>
          <van-row class="hedrow">
            <van-col span="11">姓名：{{orderData.customerName}}</van-col>
            <van-col class="ris" span="13">
              <div class="dis">进场日期：</div>
              <div
                class="dis shijian"
              >{{orderData.createdDate?orderData.createdDate.substring(0,16):''}}</div>
            </van-col>
          </van-row>
          <van-row class="hedrow">
            <van-col span="11">手机：{{orderData.phone}}</van-col>
            <van-col class="ris" span="13">
              <div class="dis">结算日期：</div>
              <div
                class="dis shijian"
              >{{orderData.settlementDate?orderData.settlementDate.substring(0,16):''}}</div>
            </van-col>
          </van-row>
          <van-cell-group class="m-t-10 p-10 p-l-5" v-if="orderData.orderComboDTO">
            <van-cell
              title="购置套餐"
              style="border-left: 4px solid #2A9F58;margin-left: 5px; height: 30px; line-height: 30px;padding: 0; padding-left: 5px;"
            >
              <em>
                <span
                  style="color:red;"
                >￥{{orderData.orderComboDTO?orderData.orderComboDTO.price.toFixed(2):''}}</span>
              </em>
            </van-cell>
          </van-cell-group>
          <van-cell-group v-if="orderData.orderComboDTO">
            <van-cell :title="orderData.orderComboDTO.comboName">
              <div class="w-100 t-r">
                <em>￥{{(orderData.orderComboDTO?orderData.orderComboDTO.price.toFixed(2):'')}}</em>
              </div>
            </van-cell>
          </van-cell-group>
          <van-row class="hedrow" style="border-top: 4px solid #f5f5f5;">
            <van-col span="8" style="border-left: 4px solid #2A9F58;padding-left: 5px">工时费</van-col>
            <van-col span="4" class="cet">单价</van-col>
            <van-col span="4" class="cet">数量</van-col>
            <van-col span="4" class="cet">券/折减</van-col>
            <van-col span="4" class="cet">金额</van-col>
          </van-row>
          <van-row
            class="hedrow"
            style="border-top: 1px solid #f5f5f5;"
            v-for="(item,index) in orderData.orderItemList"
            :key="index"
          >
            <van-col span="8" style="padding-left: 5px">{{item.itemName}}</van-col>
            <van-col span="4" class="cet">{{item.standPrice?item.standPrice.toFixed(2):'0.0'}}</van-col>
            <van-col span="4" class="cet">{{item.counts}}</van-col>
            <van-col
              span="4"
              class="cet"
            >{{item.couponDiscount?item.couponDiscount.toFixed(2):'0.00'}}</van-col>
            <van-col span="4" class="cet">￥{{(item.standPrice*item.counts).toFixed(2)}}</van-col>
          </van-row>
          <van-row class="hedrow" style="border-top: 4px solid #f5f5f5;" v-if="pjisok == 'true'">
            <van-col span="8" style="border-left: 4px solid #2A9F58;padding-left: 5px">配件费</van-col>
            <van-col span="4" class="cet">单价</van-col>
            <van-col span="4" class="cet">数量</van-col>
            <van-col span="4" class="cet">券/折减</van-col>
            <van-col span="4" class="cet">金额</van-col>
          </van-row>
          <div
            class="hedrows"
            v-for="(item,indexs) in orderData.orderItemList"
            :key="'i'+indexs"
            v-if="item.orderPartList.length > 0"
          >
            <van-row
              v-for="(item2,index2) in item.orderPartList"
              :key="index2"
              style="border-top: 1px solid #f5f5f5;"
            >
              <van-col span="8" style="padding-left: 5px">{{item2.partName}} {{index2}}</van-col>
              <van-col span="4" class="cet">{{item2.price?item2.price.toFixed(2):'0.0'}}</van-col>
              <van-col span="4" class="cet">{{item2.counts}}</van-col>
              <van-col
                span="4"
                class="cet"
              >{{item2.couponDiscount?item2.couponDiscount.toFixed(2):'0.00'}}</van-col>
              <van-col span="4" class="cet">￥{{(item2.price*item.counts)}}</van-col>
            </van-row>
          </div>

          <!-- <van-row class="hedrow" style="border-top: 4px solid #f5f5f5;">
                    <van-col span="12">应收：</van-col>
                    <van-col span="12" style="text-align: right">
                        ￥{{orderData.amountReceivable?orderData.amountReceivable.toFixed(2):'0.00'}}
                    </van-col>
          </van-row>-->
          <van-row
            class="hedrow"
            style="border-top: 4px solid #f5f5f5;"
            v-if="orderData.amountDiscount"
          >
            <van-col span="12">优惠：</van-col>
            <van-col
              span="12"
              style="color: red;text-align: right"
            >-￥{{orderData.amountDiscount?orderData.amountDiscount.toFixed(2):'0.00'}}</van-col>
          </van-row>
          <van-row class="hedrow" style="border-top: 4px solid #f5f5f5;" v-if="orderData.advance">
            <van-col span="12">预付：</van-col>
            <van-col
              span="12"
              style="color: #45b97c;text-align: right"
            >-￥{{orderData.advance?orderData.advance.toFixed(2):'0.00'}}</van-col>
          </van-row>
          <van-row class="hedrow" style="border-top: 4px solid #f5f5f5;">
            <van-col span="24" style="text-align: right">
              <span style="margin-right: 10px;">合计:</span>
              <span
                style="color: red;"
              >￥{{orderData.amountActual?orderData.amountActual.toFixed(2):'0.00'}}</span>
            </van-col>
          </van-row>
        </div>
      </div>

      <div class="bottts">
        <div class="left jiner">
          <span style="font-size: 16px">¥</span>
          <span>{{orderData.amountActual?(orderData.amountActual-offset).toFixed(2):'0.00'}}</span>
        </div>
        <div class="right">
          <van-button
            type="danger"
            size="small"
            round
            class="weixin"
            @click="pays"
            v-preventReClick
            v-if="orderData.settlementStatus != 1"
          >结算</van-button>
          <span v-else class="yizhifu">已支付</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import orderModel from "../../api/order";
import utils from "../../assets/utils";
import { ImagePreview } from "vant";
import preventReClick from "../../assets/preventReClick";
import {
  getSettlementOrder,
  fixpay
} from "../../api/check.js";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  //   mixins: [preventBack], //注入
  components: {},
  data() {
    return {
      list: [],
      checked: false,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      orderData: {},
      form: {},
      pjisok: "true",
      payType:1,//1微信支付 2储值卡支付
      offset: 0,
      active: "0",
      gid: this.$route.query.gid,
      oid: this.$route.query.oid,
      billNumber: this.$route.query.billNumber
    };
  },
  methods: {
    pays() {
      var _this = this;
      const data = {
        billNumber: this.orderData.billNumber,
        oid: this.oid,
        payType: 1,
        totalMoney: this.orderData.amountActual,
        gid: this.gid,
        type: 1,
      };
      fixpay(data)
        .then((e) => {
          if (e.code == 200) {
            // clearInterval(_this.timers);
            if (e.data.success == "true") {
      
                window.location.href = e.data.qrurl;
            } else {
              _this.$toast(e.data.msg);
            }

            // if (this.zfriode == 1) {
            //   this.downloadData = {
            //     url: e.data.code_url,
            //     icon: require("../../assets/img/weixin.png") //二维码中间的图片,可以不设置
            //   };
            //   _this.getOrderstate(e.data.out_trade_no);
            // } else {
            //   this.downloadData = {
            //     url: e.data.code_url,
            //     icon: require("../../assets/img/zhifubao.png") //二维码中间的图片,可以不设置
            //   };
            //   _this.getOrderstatezhi(e.data.out_trade_no);
            // }
          } else {
            _this.zfriode = 1;
          }
        })
        .catch(() => loading.clear());
    },
    onBridgeReady(data) {
      console.log("调用微信支付WeixinJSBridge");
      var vm = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          // 下面参数内容都是后台返回的
          appId: data.appId, // 公众号名称，由商户传入
          timeStamp: data.timeStamp, // 时间戳
          nonceStr: data.nonceStr, // 随机串
          package: data.package, // 预支付id
          signType: data.signType, // 微信签名方式
          paySign: data.paySign // 微信签名
        },
        function(res) {
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            vm.$toast.success("支付成功");
            setTimeout(() => {
              vm.$router.go(0);
            }, 1000);
          } else {
            vm.$toast("支付失败");
          }
        }
      );
    },



    changeyuer(e) {
      if (e == true) {

        if (this.orderData.amountActual >= this.form.balance) {
          this.offset = this.form.balance;
          if(this.orderData.amountActual == this.form.balance){
            this.payType = 2
          }else{
            this.payType = 1
          }
          
          console.log('this.payType1');

        } else {
          this.offset = this.orderData.amountActual;
          this.payType = 1
          console.log('this.payType2');
         
        }
      } else {
        this.offset = 0;
      }
         console.log(this.payType);
    },
    //点击放大图片
    getImg(images) {
      ImagePreview([images]);
    },
    onLoad() {
      var _this = this
      const data = {
        gid: this.gid,
        oid: this.oid,
        billNumber: this.billNumber
      };

      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
    getSettlementOrder(data)
        .then(e => {
          loading.clear();
          this.orderData = e.data;
          var orderItemList = e.data.orderItemList;
          var orderPartList = [];
          for (var i in orderItemList) {
            for (var j in orderItemList[i].orderPartList) {
              orderPartList.push(orderItemList[i].orderPartList[j]);
            }
            // console.log(orderItemList[i])
          }
          if (orderPartList.length > 0) {
            _this.pjisok = "true";
          } else {
            _this.pjisok = "false";
          }
        })
        .catch(() => loading.clear());
    },
    towNumber(val) {
      if (val == undefined) return val;
      return val.toFixed(2);
    },
    oneNumber(val) {
      if (val == undefined) return val;
      return val.toFixed(1);
    },
    back() {
      this.$router.go(-1);
    }
  },
  created() {
    this.onLoad();
  },
  mounted() {},
  computed: {
    proTotal() {
      if (
        this.orderData.orderItemList !== undefined &&
        this.orderData.orderItemList !== null &&
        this.orderData.orderItemList.length > 0
      ) {
        let sum = this.orderData.orderItemList.reduce(function(
          accumulator,
          currentValue
        ) {
          return accumulator + currentValue.standPrice * currentValue.counts;
        },
        0);
        return sum;
      }
    },
    pjTotal() {
      let pjTotal = 0;
      if (
        this.orderData.orderItemList !== undefined &&
        this.orderData.orderItemList !== null &&
        this.orderData.orderItemList.length > 0
      ) {
        this.orderData.orderItemList.map(item => {
          if (
            item.orderPartList !== undefined &&
            item.orderPartList !== null &&
            item.orderPartList.length > 0
          ) {
            item.orderPartList.map(items => {
              pjTotal += items.price * items.counts;
            });
          }
        });
        return pjTotal;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.van-checkbox__label--disabled {
  color: #666;
}

.cimg {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.bimg {
  width: 25%;
  margin: 10px 10px;
  height: 90px;
}

.bimg:nth-of-type(n + 2) {
  /* margin-left: 5%; */
}

#signature {
  z-index: 999;
}

.ris {
  text-align: right;
  overflow: hidden;
}

.dis {
  display: inline-block;
}
.shijian {
  min-width: 115px;
  text-align: left;
}
.hedrow {
  background: #fff;
  padding: 10px 10px;
  font-size: 13px;
  color: #333;
  position: relative;
}

.hedrows .van-row {
  background: #fff;
  padding: 10px 10px;
  font-size: 13px;
  color: #333;
  position: relative;
}

.qmimg {
  width: 60px;
  height: 30px;
  vertical-align: middle;
}

.heng {
  width: 30%;
  position: absolute;

  bottom: 0;
  /* left: 0; */
  margin-left: 10px;
  height: 2px;
  background: #2a9f58;
}

.cet {
  text-align: center;
}

.bottts {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 45px;
  line-height: 45px;
  background: #fff;
  overflow: hidden;
}

.jiner {
  font-size: 22px;
  color: red;
  padding-left: 12px;
  text-align: left;

  /* font-weight: bold; */
}

.weixin {
  width: 100px;
  margin-right: 12px;
  display: block;
  margin-top: 8px;
}

.yizhifu {
  color: red;
  padding-right: 12px;
  font-size: 18px;
}

//----------------------------------------
.hestou {
  padding: 46px 0;
  height: 100%;
  background: #f7f9fc;
}
.mian {
  margin: 12px 12px;
  background: #fff;
  padding-bottom: 10px;
  overflow: hidden;
}
.yuers {
  width: 94%;
  height: 50px;
  position: fixed;
  bottom: 0;
  left: 50%;
  margin-left: -47%;
  margin-bottom: 55px;
  background: #fff;
  border-radius: 5px;
  font-size: 13px;
  overflow: hidden;
  padding: 8px 12px;
}
.czimg {
  float: left;
  width: 24px;
  margin-right: 8px;
  margin-top: 2px;
}
.yes_a {
  line-height: 16px;
}
.jiaz {
  margin-top: 6px;
  margin-right: 4px;
  color: #ee0a24;
}
.van-checkbox {
  margin-top: 8px;
}
.ysee {
  color: #666;
  font-size: 12px;
}
</style>
<style lang="less">
.van-tabs__line {
  bottom: 20px;
}
</style>